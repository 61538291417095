export function showModal(type, data) {
    let tmpElement = document.getElementById('wrapper');
    tmpElement.className = 'blur';
    return {
        type: 'SHOW_MODAL',
        content: modalData(type, data)
    };
}

export function hideModal() {
    let tmpElement = document.getElementById('wrapper');
    tmpElement.className = '';
    return {
        type: 'HIDE_MODAL',
        content: {
            title: '',
            description: '',
        }
    };
}

function modalData(type, data) {
    switch (type){
        case 'NONCOMMERCIALCARD_REPORT_MODAL':
            return {
                type: 'NONCOMMERCIALCARD_REPORT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'AGGREGATEDCARD_REPORT_MODAL':
            return {
                type: 'AGGREGATEDCARD_REPORT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'PERIODIC_REPORT_MODAL':
            return {
                type: 'PERIODIC_REPORT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'INFO_MODAL':
            return {
                type: 'INFO_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'CARD_EDIT_MODAL':
            return {
                type: 'CARD_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'COMPANY_EDIT_MODAL':
            return {
                type: 'COMPANY_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'DEPARTMENT_CREATE_MODAL':
            return {
                type: 'DEPARTMENT_CREATE_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'DEPARTMENT_EDIT_MODAL':
            return {
                type: 'DEPARTMENT_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'DEPARTMENT_DELETE_MODAL':
            return {
                type: 'DEPARTMENT_DELETE_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'EMPLOYEE_EDIT_MODAL':
            return {
                type: 'EMPLOYEE_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'COMPANY_MOVEMENT_CREATE_MODAL':
            return {
                type: 'COMPANY_MOVEMENT_CREATE_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'COMPANY_MOVEMENT_EDIT_MODAL':
            return {
                type: 'COMPANY_MOVEMENT_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'COMPANY_MOVEMENT_DELETE_MODAL':
            return {
                type: 'COMPANY_MOVEMENT_DELETE_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'REGISTER_AUDIT_CREATE_MODAL':
            return {
                type: 'REGISTER_AUDIT_CREATE_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'REGISTER_AUDIT_EDIT_MODAL':
            return {
                type: 'REGISTER_AUDIT_EDIT_MODAL',
                title: '',
                description: '',
                data: data
            };
        case 'REGISTER_AUDIT_DELETE_MODAL':
            return {
                type: 'REGISTER_AUDIT_DELETE_MODAL',
                title: '',
                description: '',
                data: data
            };
        default:
            break;
    }
}
