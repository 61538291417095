import axios from 'axios';
import qs from 'qs';
import store from '../store';

import config from '../config/config';

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) return Promise.reject(error);
        const {status} = error.response;
        if (status === UNAUTHORIZED) {
            store.getState().user.userCredentials.authContext.logout();
        }
        return Promise.reject(error);
    }
);

const url = config.url;

export default {
    apiCreateReport(data){
        return sendRequest().get(`${config.urlReports}/${data.route}/${data.company}/0${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiCreatePeriodicReport(data){
        return sendRequest().get(`${config.urlReports}/${data.route}/${data.station}${data.fromDate != null ? "/" + data.fromDate : ''}${data.toDate != null ? "/" + data.toDate : ''}`, { responseType: 'arraybuffer', headers: {'Accept': 'application/vnd.ms-excel'} });
    },

    apiGetStationsList(){
        return sendRequest().get(`${url}/addressset?$expand=City`);
    },

    apiGetFuelList(){
        return sendRequest().get(`${url}/TypeFuelset?$orderby=FuelName`);
    },

    apiDepartmentGetCompanyList(){
        return sendRequest().get(`${url}/companyset?$orderby=Name`);
    },

    apiGetTypeTermList(data){
        return sendRequest().get(`${url}/typetermset?$orderby=TermName`, data);
    },

    /********************************************/

    apiGetDepartmentData(data){
        const filter = typeof data != 'undefined' ? `&$filter=CompanyID eq ${data.ID}` : '';
        return sendRequest().get(`${url}/DepartmentSet?$expand=TypeTerm, Company ${filter}`, data);
    },

    apiCreateDepartment(data){
        return sendRequest().post(`${url}/departmentset`, data);
    },

    apiEditDepartment(data){
        return sendRequest().patch(`${url}/departmentset/${data.ID}`, data);
    },

    apiDeleteDepartment(data){
        return sendRequest().delete(`${url}/departmentset/${data.ID}`);
    },

    /********************************************/

    apiGetCompanyData({ extend, top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $orderby: 'Name'
        }
        if (extend) params.$expand = extend.join(' ,');
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/CompanySet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiEditCompany(data, ID){
        return sendRequest().patch(`${url}/companyset/${ID}`, data);
    },


    apiGetStationByCompanyIDList(ID){ 
        return sendRequest().get(`${url}/companyset/${ID}/addressesbycompanyid`);
    },

    apiGetStationList() {
        const params = {
            $expand: 'City',
            $select: 'Street,ID'
        }

        return sendRequest().get(`${url}/addressset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetCompanyList(){
        return sendRequest().get(`${url}/companyset?$select=ID,Name&$orderby=Name`);
    },

    apiGetCompaniesByAddressIDList(ID){
        return sendRequest().get(`${url}/companyset/${ID}/companiesbyaddressid`);
    },

    /********************************************/

    apiGetEmployeeData({ ID, skip, top, order = [], filter = [] } = {}){
        const params = {
            $count: true,
            $expand: 'Card, Department($expand=Company), TypeTerm',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        const filterParams = [...filter]
        if (ID) filterParams.push(`DepartmentID eq ${ID}`)
        if (filterParams.length) params.$filter = filterParams.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/employeeset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiEditEmployee(data){
        return sendRequest().patch(`${url}/employeeset/${data.ID}`, data);
    },

    /********************************************/

    apiGetRegisterData({ filter = [], order = [], skip, top } = {}){
        const params = {
            $count: true,
            $expand: 'Company($expand=TypeRegister), TypeFuel',
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');

        return sendRequest().get(`${url}/registerextendedset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetLoanData({ filter = [], order = [], skip, top } = {}){
        const params = {
            $count: true,
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');

        return sendRequest().get(`${url}/loancoverageset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    /********************************************/

    apiGetCompanyMovementData({ filter = [], order = [], skip, top } = []){
        const params = {
            $count: true,
            $expand: 'Company, TypeFuel',
            $skip: skip,
            $top: top,
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/CompanyBalanceSet?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiCreateCompanyMovement(data){
        return sendRequest().post(`${url}/CompanyBalanceSet`, data);
    },


    apiEditCompanyMovement(data){
        return sendRequest().patch(`${url}/CompanyBalanceSet/${data.ID}`, data);
    },


    apiDeleteCompanyMovement(data){
        return sendRequest().delete(`${url}/CompanyBalanceSet/${data.ID}`);
    },

    /********************************************/

    apiGetRegisterAuditData({ filter = [], order = [], skip, top} = {}){
        const params = {
            $count: true,
            $expand: 'AuditFuel($expand=Barrel($expand=TypeFuel)), Employee($expand=Department($expand=Company))',
            $skip: skip,
            $top: top,
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/AuditEmployeeSet?${qs.stringify(params, {encodeValuesOnly: true})}`);
    },

    apiCreateRegisterAudit(data){
        return sendRequest().post(`${url}/AuditEmployeeSet`, data);
    },


    apiEditRegisterAudit(data){
        return sendRequest().patch(`${url}/AuditEmployeeSet/${data.ID}`, data);
    },


    apiDeleteRegisterAudit(data){
        return sendRequest().delete(`${url}/AuditEmployeeSet/${data.ID}`);
    },

    apiGetCompanyPriceData({ top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $expand: 'Company, TypeFuel',
            $top: top,
            $skip: skip
        }
        if (filter.length) params.$filter = filter.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/companypriceset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiGetCardData({ ID, top, skip, filter = [], order = [] } = {}){
        const params = {
            $count: true,
            $expand: 'employees($select=id)'
        }
        if (top) params.$top = top;
        if (skip) params.$skip = skip;
        const filterParams = [...filter]
        if (ID) filterParams.push(`ID eq ${ID}`);
        if (filterParams.length) params.$filter = filterParams.join(' and ');
        if (order.length) params.$orderby = order.join(',');
        return sendRequest().get(`${url}/cardset?${qs.stringify(params, { encodeValuesOnly: true })}`);
    },

    apiEditCard(data){
        return sendRequest().patch(`${url}/cardset/${data.ID}`, data);
    },

};

function sendRequest(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().user.userCredentials.token}`;
    return axios;
}
