import { toPascalCase } from "../services/helpers";

const toSelectList = (nameField, idField) => (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item[idField], label: item[nameField] };
    })
}

export const toBarrelFuelList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: item.TypeFuel.FuelName };
    })
}

export const toEmployeeList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: `${item.Surname} ${item.Name} ${item.Patronymic}` };
    })
}

export const toStationList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: `${item.City.Name}, ${item.Street}` };
    })
}

export const toStationAdressesList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.AddressID, label: `${item.CityName}, ${item.AddressStreet}` };
    })
}

export const toCompaniesList = (list) => {
    list.data.value = toPascalCase(list.data.value);
    return list.data.value.map((item, i) => {
        return { value: item.ID, label: item.CompanyName };
    })
}

export const toCompanyList = toSelectList('Name', 'ID');
export const toDepartmentList = toSelectList('Name', 'ID');
export const toFuelList = toSelectList('FuelName', 'ID');
export const toTypeTermList = toSelectList('TermName', 'ID');
export const toAuditTypeList = toSelectList('AuditFuelName', 'ID');
export const toTypePaymentList = toSelectList('PaymentName', 'ID');
export const toCardList = toSelectList('Name', 'ID');