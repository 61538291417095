import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { hideModal } from '../../actions/modals';

import CardEditModal from '../dashboard/cod/items/card/items/CardEditModal';

import CompanyEditModal from "../dashboard/cod/items/company/items/CompanyEditModal";

import EmployeeEditModal from "../dashboard/cod/items/employee/items/EmployeeEditModal";

import CompanyMovementCreateModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementCreateModal";
import CompanyMovementEditModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementEditModal";
import CompanyMovementDeleteModal from "../dashboard/cod/items/companyMovement/items/CompanyMovementDeleteModal";

import RegisterAuditCreateModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditCreateModal";
import RegisterAuditEditModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditEditModal";
import RegisterAuditDeleteModal from "../dashboard/cod/items/registerAudit/items/RegisterAuditDeleteModal";
import NoncommercialcardReport from "../dashboard/reports/items/NoncommercialcardReport";
import PeriodicReport from "../dashboard/reports/items/PeriodicReport";
import AggregatedCardReport from "../dashboard/reports/items/AggregatedCardReport";

import InfoModal from "./InfoModal";

const ModalsByTypes = {
    PERIODIC_REPORT_MODAL: PeriodicReport,
    NONCOMMERCIALCARD_REPORT_MODAL: NoncommercialcardReport,
    AGGREGATEDCARD_REPORT_MODAL: AggregatedCardReport,
    CARD_EDIT_MODAL: CardEditModal,
    COMPANY_EDIT_MODAL: CompanyEditModal,
    EMPLOYEE_EDIT_MODAL: EmployeeEditModal,
    COMPANY_MOVEMENT_CREATE_MODAL: CompanyMovementCreateModal,
    COMPANY_MOVEMENT_EDIT_MODAL: CompanyMovementEditModal,
    COMPANY_MOVEMENT_DELETE_MODAL: CompanyMovementDeleteModal,
    REGISTER_AUDIT_CREATE_MODAL: RegisterAuditCreateModal,
    REGISTER_AUDIT_EDIT_MODAL: RegisterAuditEditModal,
    REGISTER_AUDIT_DELETE_MODAL: RegisterAuditDeleteModal,
    INFO_MODAL: InfoModal,
}

const Modals = ({ show, hideModalProps, content }) => {
    const ModalBodyComponent = ModalsByTypes[content.type];
    if (!ModalBodyComponent) return (<div/>);
    return (
      <Modal show={show} onHide={hideModalProps}>
          <ModalBodyComponent propsData={content.data} closeModal={hideModalProps} />
      </Modal>
    )
}

const mapStateToProps = (state) => {
    return{
        show: state.modals.show,
        content: state.modals.content
    }
};

const mapDispatchToProps = (dispatch) => {
  return{
      hideModalProps: () => dispatch(hideModal())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);

